import React, { FC, ReactNode, Suspense, useEffect } from "react";

import AppLoader from "components/common/AppLoader/AppLoader";
import AppNotification from "components/common/AppNotification/AppNotification";
import { useAppSelector } from "core/helpers/index";
import { useAuth } from "hooks/core/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "components/common/Error/ErrorBoundaryFallback";
import { useDispatch } from "react-redux";
import { setFirstDayOfTheWeek, setServerTimezone, setTimezones } from "store/slices/appSlicer";
import useAppSettings from "hooks/core/useAppSettings";
import { useLSActions } from "hooks/core";
import sortTimezonesByUSAFirst from "core/utils/timezones";
import { tz } from "moment-timezone";
import { findTimezoneMatch } from "core/utils";
import useSubWorkOrderItem from "hooks/admin/WO/useSubWorkOrderItem";
import useLocalStorage from "hooks/common/useLocalStorage";

type TProps = {
  children: ReactNode;
};

const AppWrapper: FC<TProps> = ({ children }) => {
  const { getAppSettings, getTimeZones } = useAppSettings();
  const { appInitialized, currentUser } = useAppSelector((state) => state.app);
  const { checkIfLoggedInUserIsValid } = useAuth();
  const { checkTimersNew } = useSubWorkOrderItem();
  const { asyncStorageGetItem } = useLocalStorage();
  const dispatch = useDispatch();
  const { setToLs } = useLSActions();

  const initAppSettings = async () => {
    if (appInitialized && currentUser) {
      getTimeZones().then((data) => {
        getAppSettings().then((appSettings) => {
          const serverTimeZone = data.find(
            (timezone) => timezone.id === appSettings.serverTimeZone,
          );

          if (!serverTimeZone) return;
          const momentTimeZone = findTimezoneMatch(serverTimeZone, tz.names());
          dispatch(setFirstDayOfTheWeek(appSettings.firstDayOfTheWeek));
          dispatch(
            setServerTimezone({
              ...serverTimeZone,
              momentTzId: momentTimeZone,
            }),
          );
          if (momentTimeZone) {
            console.log(momentTimeZone);
            tz.setDefault(momentTimeZone);
          }
          dispatch(setTimezones(sortTimezonesByUSAFirst(data)));
          setToLs("serverTimezone", JSON.stringify(serverTimeZone));
          setToLs("timezones", JSON.stringify(data));
        });
      });
    }
  };

  useEffect(() => {
    checkIfLoggedInUserIsValid();
  }, []);

  useEffect(() => {
    initAppSettings();
  }, [appInitialized, currentUser]);

  useEffect(() => {
    const checkAndHandleTimers = async () => {
      const token = await asyncStorageGetItem("token");
      if (token) {
        checkTimersNew();
      }
    };

    if (appInitialized && currentUser) {
      checkAndHandleTimers();
    }
  }, [checkTimersNew, appInitialized, currentUser]);

  // useEffect(() => {
  //   const checkAndHandleTimers = async () => {
  //     const token = await asyncStorageGetItem("token");
  //     if (token) {
  //       checkTimers();
  //     }
  //   };

  //   if (appInitialized && currentUser) {
  //     checkAndHandleTimers();
  //   }
  // }, [checkTimers, appInitialized, currentUser]);

  return (
    <>
      {/* fallback={<AppLoader />}  */}
      <Suspense fallback={<AppLoader />}>
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          {appInitialized ? children : null}
        </ErrorBoundary>
      </Suspense>
      {/* {showLoader && <AppLoader />} */}
      <AppNotification />
    </>
  );
};

export default AppWrapper;
