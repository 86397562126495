import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";

import { IWorkOrder } from "core/types/entities/IWorkOrder";
import { useBaseAction, useUserRole } from "hooks/core";
// import useLocalStorage from "hooks/common/useLocalStorage";
import useToggle from "hooks/common/useToggle";

import { API } from "constants/RestApi";
import { selectTimers, setTimer } from "store/slices/appSlicer";
import { ILocalTimer, ITimeExpense } from "core/types/entities/IExpense";
import { useAppDispatch, useAppSelector } from "core/helpers";
import WorkOrderContext from "context/WorkOrderContext";
import { IAssignedTechnician } from "core/types/entities/ITechnician";
import { UserPermissions } from "core/types/enums/PermissionsEnum";

const useSubWorkOrderItem = () => {
  const { id } = useParams();
  const { request } = useBaseAction();
  const dispatch = useAppDispatch();
  const { getWorkOrder } = useContext(WorkOrderContext);
  const { currentUser } = useAppSelector((state) => state.app);
  const timers = useAppSelector(selectTimers);

  const { value: activeTracker, toggle, setValue } = useToggle();
  // const { asyncStorageGetItem } = useLocalStorage();
  const [currentTech, setCurrentTech] = useState<IAssignedTechnician | null>(null);

  const { permissionGranted } = useUserRole();
  const canDoWorkOrders = permissionGranted([UserPermissions["WorkOrder.CanDoWorkOrders"]]);
  const matches = useMediaQuery("only screen and (min-width: 992px)");

  const checkTimersNew = useCallback(async () => {
    if (currentUser?.id) {
      try {
        // Fetch data from the new endpoint
        const responseTimers = (await request({
          method: "GET",
          url: `${API.OFFICE_TIMERS}`,
          params: {
            $filter: `endTime eq null and userId eq ${currentUser.id}`,
            expand:
              "User($select=firstName,lastName),OSR.SubWorkOrderExpenseTimeTrackingRecord/Expense($expand=subWorkOrder($select=workOrderId))",
            $orderby: "startTime desc",
          },
          useLoader: false,
        })) as any[];

        // Transform the new data format to match the old data format
        const transformedTimers = responseTimers.map((timer) => {
          const {
            id: timerId,
            userId,
            startTime,
            recordType,
            type,
            endTime,
            expense,
            rowVersion,
          } = timer;
          const newTimer: any = {
            id: timerId.toString(),
            userId,
            key: "",
            startTime,
            rowVersion,
            extensionData: {},
          };

          // Determine if it's an office timer or a work order timer
          if (recordType === "TimeTracking" && type === "ClockInOut") {
            // Office timer
            const randomString = Math.random().toString(36).substr(2, 8);
            newTimer.key = `office_timer_${randomString}`;
            newTimer.extensionData = {
              active: endTime == null,
              timerType: "officeTimer",
            };
          } else if (recordType === "SWOExpense") {
            // Work Order timer
            const workOrderId = expense?.subWorkOrder?.workOrderId?.toString();
            const appointmentId = expense?.subWorkOrderId?.toString();
            newTimer.key = `${workOrderId}_${appointmentId}`;
            newTimer.extensionData = {
              active: endTime == null,
              recordType: expense?.recordType || "Labor",
              appointment: {
                id: appointmentId,
              },
              workOrderId,
              startTime: new Date(startTime).getTime(),
              id: timer.expense?.id,
            };
          }

          return newTimer;
        });
        // Proceed with the existing logic using the transformed data
        const hasActiveTimers = transformedTimers.length > 0;

        // const WOTimers = transformedTimers.filter(
        //   (WOTimer) => (WOTimer.extensionData as any)?.workOrderId,
        // );

        // const localWOTimers = timers.filter(
        //   (WOTimer) => (WOTimer.extensionData as any)?.workOrderId,
        // );

        // Check if the timer already exists to prevent unnecessary re-renders
        // const timerAlreadyExist = WOTimers.some((timer) =>
        //   localWOTimers.some((localTimer) => localTimer.key === timer.key),
        // );

        // Check if both timers are empty
        // const bothTimersIsEmpty = !WOTimers.length && !localWOTimers.length;

        // if (!bothTimersIsEmpty) {
        dispatch(setTimer(hasActiveTimers ? transformedTimers : []));
        // Optionally, you can handle asyncStorage here if needed
        // }

        return hasActiveTimers;
      } catch (error) {
        console.error("Error fetching or processing timers:", error);
        return false; // Return false in case of an error
      }
    }

    // Return false if currentUser?.id is not present
    return false;
  }, [currentUser, dispatch]);

  const createTimerRequest = async (payload: ILocalTimer) => {
    await request({
      method: "POST",
      url: API.OFFICE_TIMERS,
      successMessage: "Timer has been started",
      useLoader: false,
      body: payload,
    });
  };

  const requestUpdateExpense = async (form: ITimeExpense) => {
    await request({
      method: "PUT",
      url: API.SUB_WORK_ORDER_EXPPENSES,
      body: form,
      successMessage: "Item has been updated",
      useLoader: true,
    });
    await checkTimersNew();
  };

  const requestCreateExpense = async (form: ITimeExpense, subWO: IWorkOrder) => {
    await request({
      method: "POST",
      url: API.SUB_WORK_ORDER_EXPPENSES,
      body: {
        ...form,
        subWorkOrderId: subWO.id,
      },
      successMessage: "Item has been created",
      useLoader: true,
    });
    await checkTimersNew();
  };

  const checkTimers = useCallback(async () => {
    const responseTimers = (await request({
      method: "GET",
      url: `${API.WORK_ORDERS_TIMERS}`,
      useLoader: false,
    })) as ILocalTimer[];

    const hasActiveTimers = Boolean(
      responseTimers.filter((timer) => timer.extensionData as any).length,
    );

    const WOTimers = responseTimers.filter(
      (WOTimer) => (WOTimer as any)?.extensionData?.workOrderId,
    );

    const localWOTimers = timers.filter((WOTimer) => (WOTimer as any)?.extensionData?.workOrderId);

    // If timer is already exists in storage, we don't need to set it again to prevent component re-render
    const timerAlreadyExist = WOTimers.some((timer) =>
      localWOTimers.some((localTimer) => localTimer.key === timer.key),
    );

    // If local timers and response timers are empty, we don't need to set it to prevent component re-render
    const bothTimersIsEmpty = !WOTimers.length && !localWOTimers.length;

    if (!timerAlreadyExist && !bothTimersIsEmpty) {
      dispatch(setTimer(hasActiveTimers ? responseTimers : []));
      // await asyncStorageSetItem("timers", hasActiveTimers ? JSON.stringify(responseTimers) : "");
    }

    return hasActiveTimers;
  }, []);

  const handlerActiveTimer = async (
    active: boolean,
    appointment: IWorkOrder,
    recordType: string,
  ) => {
    // const timersListFromLS = await asyncStorageGetItem("timers");
    const key = `${id}_${appointment.id}`;
    const newTimer = {
      key,
      extensionData: {
        active,
        recordType,
        appointment: { id: appointment.id },
        workOrderId: id ?? "",
        startTime: Date.now(),
      },
    };

    if (active) {
      // if (
      //   timersListFromLS &&
      //   (timersListFromLS as Array<any>)[0]?.extensionData?.workOrderId &&
      //   (timersListFromLS as Array<any>)[0]?.extensionData?.workOrderId !== id
      // ) {
      //   dispatch(
      //     setNotification({ message: "You have active timer in another WO", type: "error" }),
      //   );
      // } else {
      await createTimerRequest(newTimer);
      // await asyncStorageSetItem("timers", JSON.stringify(newTimer));
      dispatch(setTimer([...timers, newTimer]));
      // }
    } else if (appointment && appointment?.assignedTechs) {
      const assignedTechId =
        (appointment.assignedTechs.find((tech) => tech.technicianId === currentUser?.id)
          ?.id as number) ?? "0";
      await requestCreateExpense(
        {
          recordType,
          assignedTechId,
          timerKey: key,
        },
        appointment,
      );
      const filteredTimers = timers.filter((timer) => timer.key !== key);
      dispatch(setTimer(filteredTimers));
      // await asyncStorageSetItem("timers", JSON.stringify(filteredTimers));
      await getWorkOrder();
    }
  };

  const handleActiveTimerNew = async (appointment: IWorkOrder, recordType: string) => {
    const assignedTechId =
      (appointment?.assignedTechs?.find((tech) => tech.technicianId === currentUser?.id)
        ?.id as number) ?? "0";
    const currentTimer = timers.find(
      (v) => +((v.extensionData as any)?.appointment?.id ?? 0) === appointment.id,
    );
    if (!currentTimer) {
      await requestCreateExpense(
        {
          recordType,
          assignedTechId,
          startTime: new Date(),
        },
        appointment,
      );
      await getWorkOrder();

      return;
    }
    const currentExpense = appointment.expenses?.find(
      (v) => String(v.id) === String((currentTimer.extensionData as any)?.id),
    );
    await requestUpdateExpense({
      kind: recordType,
      assignedTechId,
      endTime: new Date(),
      startTime: currentTimer?.startTime ?? new Date(),
      subWorkOrderId: appointment.id,
      id: Number((currentTimer.extensionData as any)?.id ?? 0),
      rowVersion: currentExpense?.rowVersion,
    });
    await getWorkOrder();
  };

  const definitionCurrentTech = (subWO: IWorkOrder) => {
    if (subWO?.assignedTechs && subWO?.assignedTechs.length && currentUser) {
      const filteredTech = subWO?.assignedTechs.filter(
        (tech) => tech.technicianId === currentUser.id,
      );
      if (filteredTech.length) {
        setCurrentTech(filteredTech[0]);
      }
    }
  };

  return {
    activeTracker,
    toggle,
    setValue,
    handlerActiveTimer,
    requestCreateExpense,
    checkTimers,
    canDoWorkOrders,
    matches,
    currentTech,
    setCurrentTech,
    definitionCurrentTech,
    checkTimersNew,
    handleActiveTimerNew,
  };
};

export default useSubWorkOrderItem;
