const API = {
  APP_SETTINGS_TENANT: "/AppSettings/Tenant",
  APP_SETTINGS_USER: "/AppSettings/User",
  APP_SETTINGS_TIMEZONES: "AppSettings/Timezones",
  COMPANIES: "/Companies",
  COMPANIES_EQUIPMENT: "/Companies/Equipment",
  COMPANIES_CONTACTS: "/Companies/Contacts",
  COMPANIES_NOTES: "/Companies/Notes",
  COMPANIES_EQUIPMENT_NOTES: "/Companies/Equipment/Notes",
  ITEMS: "/Items",
  FILES: "/Files",
  USERS: "/Users",
  VEHICLES: "/Vehicles",
  QUOTES: "/Quotes",
  NOTES: "Notes",
  NOTIFICATIONS: "Notifications",
  QUICKBOOKS_STATUS: "/QuickBooks/SyncStatus",
  SUB_WORK_ORDERS: "/WorkOrders/SubWorkOrders",
  SUB_WORK_ORDER_CANCEL: "/WorkOrders/SubWorkOrders/Cancel",
  SUB_WORK_ORDER_EXPPENSES: "/WorkOrders/SubWorkOrders/Expenses",
  SUB_WORK_ORDER_RESCHEDULE: "/WorkOrders/SubWorkOrders/Reschedule",
  SUB_WORK_ORDER_CONTACTS: "/WorkOrders/SubWorkOrders/Contacts",
  SUB_WORK_ORDER_EQUIPMENT: "/WorkOrders/SubWorkOrders/Equipment",
  SUB_WORK_ORDER_EQUIPMENT_NOTES: "/WorkOrders/SubWorkOrders/Equipment/Notes",
  SUB_WORK_ORDER_NOTES: "/WorkOrders/SubWorkOrders/Notes",
  SUB_WORK_ORDER_TECHNICIANS: "/WorkOrders/SubWorkOrders/Technicians",
  SUB_WORK_ORDER_TECHNICIANS_SETSTATUS: "/WorkOrders/SubWorkOrders/Technicians/SetStatus",
  SUB_WORK_ORDER_VEHICLES: "/WorkOrders/SubWorkOrders/Vehicles",
  SUB_WO_EXPENSES: "/WorkOrders/SubWorkOrders/Expenses",
  WORK_ORDERS: "/WorkOrders",
  WORK_ORDERS_NOTES: "/WorkOrders/Notes",
  WORK_ORDERS_CANCEL: "/WorkOrders/Cancel",
  WORK_ORDERS_UnComplete: "/WorkOrders/UnComplete",
  WORK_ORDERS_SIGNATURE: "/WorkOrders/Signature",
  WORK_ORDERS_COMPLETE: "/WorkOrders/Complete",
  CALENDAR: "/Calendar",
  WORK_ORDERS_TIMERS: "TimeTracking/Timers",
  OFFICE_TIMERS: "TimeTracking",
  REPORTS_TIMECARD: "Reports/Timecard",
};

const defaultFilter = "isDeleted eq false";
const userByTechnicianFilter = "isActive eq true and Roles/any(role: role eq 'Technician')";
const userByTechnicianOnlyCanDoFilter =
  "isActive eq true and rolePermissions/any(p: p eq 'WorkOrder.CanDoWorkOrders')";
const addressUrl = "https://www.google.com/maps/place/";

export { API, defaultFilter, userByTechnicianFilter, addressUrl, userByTechnicianOnlyCanDoFilter };
