import { publicLinks, technichianToolLinks } from "constants/AppLinks";
import { useAppDispatch } from "core/helpers/index";
import { ILoginResponse } from "core/types/base/IAuth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setAccessToken,
  setAppInitialized,
  setCurrentUser,
  setLoader,
  setTimer,
} from "store/slices/appSlicer";
import { useLSActions, useBaseAction, useToken } from "hooks/core";
import useUserManagement from "hooks/admin/useUserManagement";
import { IUser } from "core/types/base/IUser";
import appConfig from "constants/Config";
import { UserPermissions } from "core/types/enums/PermissionsEnum";
import { ILsAuth } from "core/types/base/IToken";

type RedirectLocationState = {
  redirectTo: Location;
};

const useAuth = () => {
  const { request } = useBaseAction();
  const { accessTokenExpired, parseToken } = useToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setToLs, removeItemFromLs, getFromLs } = useLSActions();
  const { getUserById } = useUserManagement({ fetchOnLoad: false });
  const { state } = useLocation();

  const signIn = async (inputEmail: string, inputPassword: string) => {
    try {
      const response = (await request({
        method: "POST",
        body: { email: inputEmail, password: inputPassword },
        url: "Users/Login",
        isProtected: false,
      })) as ILoginResponse;
      const locationState = state as RedirectLocationState;

      if (!response.token) return;
      await setToLs<ILsAuth>("token", { token: response.token, userId: response.user.id });
      dispatch(setAccessToken(response.token || ""));
      dispatch(setCurrentUser(response.user));
      if (locationState?.redirectTo?.pathname.includes("work-orders")) {
        navigate(`${locationState?.redirectTo.pathname}`);
      } else {
        navigate(`/${technichianToolLinks.workOrdersList}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = async () => {
    try {
      await request({
        method: "POST",
        url: "Users/Logout",
      });
      navigate(`/${publicLinks.singin}`, { state: { redirectTo: null } });
      dispatch(setCurrentUser(null));
      dispatch(setAccessToken(""));
      dispatch(setTimer([]));
      removeItemFromLs("token");
      removeItemFromLs("timers");
    } catch (error) {
      console.log(error);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await request({
        method: "POST",
        body: { email },
        url: "Users/ForgetPassword",
        successMessage:
          "If user with entered email address exists, we will send the email in a few seconds.",
        isProtected: false,
        useLoader: true,
      });
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  };

  const resetPassword = async (
    password: string,
    token: string,
    email: string,
    isCompleteRegistration?: boolean,
  ) => {
    try {
      await request({
        method: "POST",
        body: { password, token: decodeURIComponent(token), email, confirmPassword: password },
        url: isCompleteRegistration ? "Users/CompleteRegistration" : "Users/ResetPassword",
        successMessage: isCompleteRegistration
          ? "Congratulations! Your registration was successful. You can login with new credentials"
          : "Your password has been changed. You can login with new credentials",
        isProtected: false,
      });
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  };

  const completeRegistration = async (password: string, token: string, email: string) => {
    await resetPassword(password, token, email, true);
  };

  const confirmEmail = async (token: string, email: string) => {
    await request({
      method: "POST",
      url: `Users/ConfirmEmail`,
      body: { token, email },
      isProtected: false,
      useLoader: true,
      // successMessage: `${email} has been confirmed. You can login with new credentials`,
    });
    // navigate("/signin");
  };

  const getCurrentUser = async (): Promise<IUser | null> => {
    const lsToken = (await getFromLs<ILsAuth>("token")) || ({} as ILsAuth);

    if (!lsToken) {
      return null;
    }
    const parsedToken = parseToken(lsToken.token);
    if (accessTokenExpired(parsedToken.exp)) {
      removeItemFromLs("token");
      return null;
    }
    try {
      const user = await getUserById(lsToken.userId);
      dispatch(setAccessToken(lsToken.token));
      return user || null;
    } catch (error) {
      return null;
    }
  };

  const checkIfLoggedInUserIsValid = async () => {
    try {
      dispatch(setLoader(true));
      dispatch(setAppInitialized(false));
      const user = await getCurrentUser();

      dispatch(setCurrentUser(user || null));
      dispatch(setAppInitialized(true));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setAppInitialized(true));
      dispatch(setLoader(false));
    }
  };

  const userCanViewRoute = (
    allowedPermissions: UserPermissions[] | undefined,
    userPermissions: UserPermissions[] | undefined,
  ) => {
    if (!userPermissions) return false;
    if (!allowedPermissions) {
      return true;
    }
    if (allowedPermissions.some((permission) => userPermissions.includes(permission))) {
      return true;
    }
    return false;
  };

  const redirectByRole = (roles: string[]) => {
    switch (roles[0]) {
      case appConfig.userRoles.admin:
        return `${technichianToolLinks.workOrdersList}`;
      case appConfig.userRoles.technician:
        return `${technichianToolLinks.workOrdersList}`;
      default:
        return "signin";
    }
  };

  return {
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
    getCurrentUser,
    userCanViewRoute,
    checkIfLoggedInUserIsValid,
    completeRegistration,
    redirectByRole,
    confirmEmail,
  };
};

export default useAuth;
