import React, { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useHandleConnection from "hooks/qb/useHandleConnection";
import "./MainLayout.scss";
import QBUnauthWarningModal from "../Modals/QBUnauthWarningModal";

type TProps = {
  children: ReactNode;
};

const MainLayout: FC<TProps> = ({ children }) => {
  const { showModal, qbAuthUrl, getQBStatuses } = useHandleConnection();
  const location = useLocation();

  const clearModalBackdrop = () => {
    const modal = document.getElementsByClassName("modal-backdrop");
    if (modal.length) {
      modal[0].remove();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getQBStatuses();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    clearModalBackdrop();
  }, [location]);

  return (
    <>
      <main className="container py-4 flex-shrink-0" id="main-layout">
        {children}
      </main>
      <QBUnauthWarningModal qbUrl={qbAuthUrl} showModal={showModal} />
    </>
  );
};

export default MainLayout;
