import { createContext } from "react";
import { IWorkOrderDetails } from "../core/types/entities/IWorkOrder";
import { ICustomReactSelectOption } from "../core/types/elements/ICustomReactSelectOption";

interface IWorkOrderContext {
  workOrder: IWorkOrderDetails | null;
  techs: ICustomReactSelectOption[];
  equipment: ICustomReactSelectOption[];
  vehicles: ICustomReactSelectOption[];
  contacts: ICustomReactSelectOption[];
  getWorkOrder: (useLoader?: boolean) => Promise<void>;
  getContacts: () => void;
  getEquipments: () => void;
  getVehicles: () => void;
  getUsers: () => void;
  qbo: IWorkOrderDetails | null;
}

const WorkOrderContext = createContext<IWorkOrderContext>({} as IWorkOrderContext);

export default WorkOrderContext;
