import { usaTimezones } from "constants/Timezones";
import { ITimeZones } from "../types/entities/IItem";

function sortTimezonesByUSAFirst(timezones: ITimeZones[]): ITimeZones[] {
  const USA_TIMEZONES = timezones
    .filter((tz) => (usaTimezones.includes(tz.displayName) ? tz : false))
    .sort((a, b) => a.baseOffset.localeCompare(b.baseOffset))
    .reverse();

  const OTHER_TIMEZONES = timezones.filter((tz) => !usaTimezones.includes(tz.displayName));

  return [...USA_TIMEZONES, ...OTHER_TIMEZONES];
}

export default sortTimezonesByUSAFirst;
