import { ITimeZones } from "core/types/entities/IItem";
import { FirstDayOfTheWeek } from "core/types/enums/FirstDayOfTheWeek";
import moment from "moment-timezone";

const defaultDateFormat = "DD MMM YYYY";

const formatDate = (date: string, format?: string) =>
  moment(date).format(format || defaultDateFormat);

const formatDateToLocalTime = (date: string, format?: string) =>
  moment(date)
    .local()
    .format(format || defaultDateFormat);

const formatUTCtoLocal = (utcDate: string, format?: string) =>
  moment
    .utc(utcDate)
    .local()
    .format(format || defaultDateFormat);

const formatUTCtoLocalStandact = (utcDate: string) =>
  moment(utcDate).format("MMM DD, YYYY hh:mm A");

const formatUTCtoLocalStandactDate = (utcDate: string) => moment(utcDate).format("MMM DD, YYYY");

const formatUTCtoStandart = (utcDate: string) => moment.utc(utcDate).format("MMM DD, YYYY hh:mm A");

const formatISOtoStandart = (date: string | Date) => moment(date).format("MMM DD, YYYY hh:mm A");

const formatUTCtoLocalStandactShort = (utcDate: string) =>
  moment(utcDate).format("MM.DD.yyyy hh:mm A");

const formatUTCtoLocalDate = (utcDate: string) => moment.utc(utcDate).local().toDate();

const getDateNow = (format?: string) => moment().format(format || defaultDateFormat);

const getOnlyFormattedDate = (datetime: string) =>
  moment(datetime.split("T")[0]).format("DD MMM YYYY");

const momentDateFormat = (date: string | Date, customFormat?: string) =>
  moment.utc(date).format(customFormat || "MMM DD, Y");

const timeSpanToUtcLocal = (time: string | Date) =>
  `${momentDateFormat(new Date(), "YYYY-MM-DD[T]")}${time}Z`;

const timeLocalToUtcFormat = (time: string | Date) =>
  moment(time).format("YYYY-MM-DD[T]HH:mm:ss[Z]");

const getReportDates = (startTime: string | Date, endTime: string | Date) => {
  return {
    startDate: moment(startTime).format("YYYY-MM-DD"),
    endDate: moment(endTime).format("MM-DD-YYYY"),
  };
};

const getLastSunday = (firstDayOfTheWeek: FirstDayOfTheWeek = FirstDayOfTheWeek.Sunday): Date => {
  if (firstDayOfTheWeek === FirstDayOfTheWeek.Monday) {
    return moment().startOf("week").subtract(1, "day").day(1).toDate();
  }
  return moment().startOf("week").subtract(1, "day").day(0).toDate();
};

const getLastSaturday = (firstDayOfTheWeek: FirstDayOfTheWeek = FirstDayOfTheWeek.Sunday): Date => {
  if (firstDayOfTheWeek === FirstDayOfTheWeek.Monday) {
    return moment().startOf("week").subtract(1, "day").day(7).toDate();
  }
  return moment().startOf("week").subtract(1, "day").toDate();
};

const diffDate = (start: string, endTime: string) => {
  const diff = moment(endTime).toDate().getTime() - moment(start).toDate().getTime();
  const SEC = 1000;
  const MIN = 60 * SEC;
  const HRS = 60 * MIN;
  const DAY = 24 * HRS;

  const days = Math.floor(diff / DAY);
  const hrs = Math.floor((diff % DAY) / HRS);
  const min = Math.floor((diff % HRS) / MIN);

  return `${days}d ${hrs}h ${min}m`;
};

// const findTimezoneMatch = (input: ITimeZones, timezoneList: string[]): any | null => {
//   console.log("----------- findTimezoneMatch -----------");
//   console.log({ id: input.id, displayName: input.displayName }, timezoneList);
//   const normalize = (str: string) => str.toLowerCase();
//   const inputNormalized = normalize(`${input.id} ${input.displayName}`).split(" ");
//   console.log(inputNormalized);

//   console.log(
//     timezoneList.filter((item) => {
//       const itemNormalized = normalize(item).split("/");
//       return inputNormalized.some((word) => itemNormalized.some((part) => part.includes(word)));
//     }) || null,
//   );
//   console.log(timezoneList.filter((item) => normalize(item).includes("warsaw")));
//   console.log("----------- findTimezoneMatch -----------");

//   return (
//     timezoneList.find((item) => {
//       const itemNormalized = normalize(item).split("/");
//       return inputNormalized.some((word) => itemNormalized.some((part) => part.includes(word)));
//     }) || null
//   );
// };

const findTimezoneMatch = (input: ITimeZones, timezones: string[]): string | null => {
  const normalize = (str: string) => str.toLowerCase().replace(/[()]/g, "");
  const inputNormalized = normalize(`${input.displayName}`);
  const words = inputNormalized.split(/[\s,]+/).filter((word) => word.length > 2);
  const scoredTimezones = timezones.map((tz) => {
    const normalizedTz = normalize(tz);
    const score = words.reduce((acc, word) => {
      if (normalizedTz.includes(word)) {
        if (normalizedTz === word || new RegExp(`\\b${word}\\b`).test(normalizedTz)) {
          return acc + 2;
        }
        return acc + 1;
      }
      return acc;
    }, 0);
    return { timezone: tz, score };
  });

  const bestMatch = scoredTimezones.sort((a, b) => b.score - a.score)[0];
  return bestMatch.score > 0 ? bestMatch.timezone : null;
};

function shiftToTimezoneAndConvertToUTC(inputDate: Date | string, targetTimezone: string) {
  let originalMoment;
  console.log(inputDate instanceof Date);
  if (inputDate instanceof Date) {
    originalMoment = moment(inputDate).local();
  } else {
    originalMoment = moment.parseZone(inputDate);
  }
  const shiftedMoment = moment.tz(
    {
      year: originalMoment.year(),
      month: originalMoment.month(),
      date: originalMoment.date(),
      hour: originalMoment.hour(),
      minute: originalMoment.minute(),
      second: originalMoment.second(),
    },
    targetTimezone,
  );

  return shiftedMoment.utc().format();
}

const convertToTargetTimezone = (utcDate: string, targetTimezone: string): Date => {
  const momentInTargetTimezone = moment.tz(utcDate, targetTimezone);
  if (!momentInTargetTimezone) return new Date();
  return new Date(
    momentInTargetTimezone.year(),
    momentInTargetTimezone.month(),
    momentInTargetTimezone.date(),
    momentInTargetTimezone.hours(),
    momentInTargetTimezone.minutes(),
    momentInTargetTimezone.seconds(),
    momentInTargetTimezone.milliseconds(),
  );
};

export {
  formatISOtoStandart,
  getLastSaturday,
  getLastSunday,
  formatUTCtoStandart,
  formatDate,
  formatUTCtoLocal,
  getDateNow,
  getOnlyFormattedDate,
  formatDateToLocalTime,
  timeSpanToUtcLocal,
  momentDateFormat,
  timeLocalToUtcFormat,
  formatUTCtoLocalStandact,
  formatUTCtoLocalDate,
  diffDate,
  formatUTCtoLocalStandactShort,
  getReportDates,
  findTimezoneMatch,
  shiftToTimezoneAndConvertToUTC,
  convertToTargetTimezone,
  formatUTCtoLocalStandactDate,
};
