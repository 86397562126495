import { API } from "constants/RestApi";
import { IBaseEntity } from "core/types/entities";
import { useBaseAction, useHandleModals } from "hooks/core";
import { useEffect, useState } from "react";

const actions = ["remove", "cancel"] as const;

type TProps = {
  onLoad?: boolean;
  removeCallback?: (id?: number) => void;
  cancelCallback?: (id?: number) => void;
  baseUrl: string;
  filter?: string;
  expand?: string;
  cancelUrl?: string;
  type?: string;
};

const useEntityManagement = <T extends IBaseEntity>({
  removeCallback,
  baseUrl,
  onLoad,
  filter,
  expand,
  cancelUrl,
  cancelCallback,
  type,
}: TProps) => {
  const [entityItems, setEntityItems] = useState<T[]>([] as T[]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { request } = useBaseAction();
  const { modals, toggleModal, setSelectedEntity, selectedEntity, setModalTitle, modalTitle } =
    useHandleModals<T, typeof actions[number]>({
      modalsKeys: actions,
    });

  const resetStates = (clearEntities?: boolean) => {
    toggleModal({ hideAll: true });
    setModalTitle("");
    setSelectedEntity(null);
    if (clearEntities) setEntityItems([]);
  };

  const getItems = async (
    filterParam?: string,
    useLoader = true,
    orderby?: string,
    addToList?: boolean,
  ) => {
    setIsLoading(true);
    const response = await request({
      method: "GET",
      url: baseUrl,
      useLoader,
      params: {
        $filter: filterParam || `isDeleted eq false${filter ? ` and ${filter}` : ""}`,
        $expand: expand || null,
        $orderby: orderby,
      },
    });
    if (addToList) {
      setEntityItems((prevItems) => {
        const newItems = response as T[];
        const uniqueItems = newItems.filter(
          (newItem) => !prevItems.some((prevItem) => prevItem.id === newItem.id),
        );
        return [...prevItems, ...uniqueItems];
      });
    } else {
      setEntityItems(response as T[]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (onLoad) getItems();
  }, []);

  const getByIdAction = async (
    id: number | string,
    filterKey?: string,
    isfilterValusString = true,
    customFilter?: string,
    useLoader = true,
  ) => {
    const response = await request({
      method: "GET",
      url: `${baseUrl}${!filterKey ? `/${id}` : ""}`,
      useLoader,
      params: {
        $filter:
          customFilter ||
          (filterKey ? `${filterKey} eq ${isfilterValusString ? `'${id}'` : id}` : null),
        $expand: expand || null,
      },
    });
    const data = Array.isArray(response) ? response[0] : response;
    setSelectedEntity(data);
    return data as T;
  };

  const createEditAction = async (submittedData: T) => {
    const { id } = submittedData;

    const res = await request({
      method: id ? "PUT" : "POST",
      url: baseUrl,
      body: submittedData,
      successMessage: `Item has been ${id ? "changed" : "created"}`,
      useLoader: true,
    });
    return res;
  };

  const NotesApiUrls = {
    C: API.COMPANIES_NOTES,
    CE: API.COMPANIES_EQUIPMENT_NOTES,
    WOCE: API.SUB_WORK_ORDER_EQUIPMENT_NOTES,
    WO: API.WORK_ORDERS_NOTES,
    SWO: API.SUB_WORK_ORDER_NOTES,
  };

  const removeAction = async () => {
    const { id, rowVersion, recordType } = selectedEntity || {};
    let requestUrl = baseUrl;
    if (type === "notes") {
      if (recordType) {
        requestUrl = NotesApiUrls[recordType as keyof typeof NotesApiUrls];
      }
    }

    await request({
      method: "DELETE",
      url: requestUrl ?? baseUrl,
      body: { id, rowVersion, isDeleted: true },
      successMessage: "Item has been removed from system",
      useLoader: true,
    });
    resetStates();
    if (removeCallback) removeCallback(id);
  };

  const cancelAction = async () => {
    const { id, rowVersion } = selectedEntity || {};
    await request({
      method: "PUT",
      url: cancelUrl ?? baseUrl,
      body: { id, rowVersion },
      useLoader: true,
      successMessage: "Item has been canceled",
    });
    resetStates();
    if (cancelCallback) cancelCallback(id);
  };

  const handleAction = (modalKey: typeof actions[number], payload?: T, itemName?: string) => {
    let title = "";

    switch (modalKey) {
      case "remove":
        title = `Are you sure that you want to delete ${itemName || ""}?`;
        break;
      case "cancel":
        title = `Are you sure that you want to cancel ${itemName || ""}?`;
        break;
      default:
        break;
    }
    if (payload) setSelectedEntity(payload);
    setModalTitle(title);
    toggleModal({ key: modalKey, value: true });
  };

  const handleConfirmAction = () => {
    const { remove, cancel } = modals;

    if (remove) {
      removeAction();
    }

    if (cancel) {
      cancelAction();
    }
  };

  return {
    modals,
    toggleModal,
    handleAction,
    selectedEntity,
    setSelectedEntity,
    modalTitle,
    handleConfirmAction,
    resetStates,
    removeAction,
    createEditAction,
    getByIdAction,
    getItems,
    entityItems,
    setEntityItems,
    isLoading,
  };
};

export default useEntityManagement;
