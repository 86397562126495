import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import AuthLayout from "components/common/Layout/AuthLayout";
import { adminToolLinks, publicLinks, technichianToolLinks } from "constants/AppLinks";
import { useAppSelector } from "core/helpers";
import { useAuth } from "hooks/core";
import { UserPermissions } from "core/types/enums/PermissionsEnum";
import ProtectedRoute from "./ProtectedRoute";

// Private Screens
// Admin Tool
const UsersManagementScreen = lazy(
  () => import("screens/private/admin-tool/UsersManagementScreen"),
);

const CompanyListScreen = lazy(
  () => import("screens/private/admin-tool/company/CompanyListScreen"),
);
const CompanyDetailsScreen = lazy(
  () => import("screens/private/admin-tool/company/CompanyDetailsScreen"),
);
const MapScreen = lazy(() => import("screens/private/admin-tool/MapScreen"));

const VehiclesListScreen = lazy(
  () => import("screens/private/admin-tool/vehicles/VehiclesListScreen"),
);
const VehiclesAddEditScreen = lazy(
  () => import("screens/private/admin-tool/vehicles/VehiclesAddEditScreen"),
);

const ContactsAddEditScreen = lazy(
  () => import("screens/private/admin-tool/company/contacts/ContactsAddEditScreen"),
);
const CompanyEquipmentAddEditScreen = lazy(
  () => import("screens/private/admin-tool/company/equipment/CompanyEquipmentAddEditScreen"),
);
const CompanyEquipmentEditTabScreen = lazy(
  () => import("screens/private/admin-tool/company/equipment/CompanyEquipmentEditTabScreen"),
);
const NotesAddEditScreen = lazy(
  () => import("screens/private/admin-tool/notes/NotesAddEditScreen"),
);

const QuotesListScreen = lazy(() => import("screens/private/admin-tool/quotes/QuotesListScreen"));
const QuotesDetailsScreen = lazy(
  () => import("screens/private/admin-tool/quotes/QuotesDetailsScreen"),
);

const EquipmentMakesListScreen = lazy(
  () => import("screens/private/admin-tool/equipment/equipmentMakes/EquipmentMakesListScreen"),
);

const QuickBooksScreen = lazy(
  () => import("screens/private/admin-tool/quick-books/QuickBooksScreen"),
);

const EquipmentTypesListScreen = lazy(
  () => import("screens/private/admin-tool/equipment/equipmentTypes/EquipmentTypesListScreen"),
);

const EquipmentModelsListScreen = lazy(
  () => import("screens/private/admin-tool/equipment/equipmentModels/EquipmentModelsListScreen"),
);

// const EquipmentTypesAddEditScreen = lazy(
//   () => import("screens/private/admin-tool/equipment/equipmentTypes/EquipmentTypesAddEditScreen"),
// );

const WorkOrdersListScreen = lazy(
  () => import("screens/private/admin-tool/work-orders/WorkOrdersListScreen"),
);

const WorkOrdersDetailsScreen = lazy(
  () => import("screens/private/admin-tool/work-orders/WorkOrdersDetailsScreen"),
);

const MyProfileScreenScreen = lazy(
  () => import("screens/private/admin-tool/profile/MyProfileScreen"),
);

const PayrollScreen = lazy(() => import("screens/private/admin-tool/payroll/PayrollScreen"));

const SalesScreen = lazy(() => import("screens/private/admin-tool/sales/SalesScreen"));

const ScheduleScreen = lazy(() => import("screens/private/admin-tool/ScheduleScreen"));
const SettingsScreen = lazy(() => import("screens/private/admin-tool/SettingsScreen"));

// Public Screens
const ForgotPasswordScreen = lazy(() => import("screens/public/ForgotPasswordScreen"));
const SetPasswordScreen = lazy(() => import("screens/public/SetPasswordScreen"));
const SignInScreen = lazy(() => import("screens/public/SignInScreen"));
const ConfirmEmailScreen = lazy(() => import("screens/public/ConfirmEmailScreen"));
const QBConnectionCompleted = lazy(() => import("screens/public/QBConnectionCompleted"));
const AccessDeniedRoute = lazy(() => import("screens/common/AccessDeniedRoute"));

// Common Screens
const NotFoundRoute = lazy(() => import("screens/common/NotFoundRoute"));

const AppRoutes = () => {
  const { redirectByRole } = useAuth();
  const { currentUser } = useAppSelector((state) => state.app);

  return useRoutes([
    {
      element: <AuthLayout />,
      children: [
        { path: publicLinks.singin, element: <SignInScreen /> },
        { path: publicLinks.forgotPassword, element: <ForgotPasswordScreen /> },
        { path: publicLinks.setPassword, element: <SetPasswordScreen /> },
        {
          path: publicLinks.completeRegistration,
          element: <SetPasswordScreen isCompleteRegistration />,
        },
        { path: publicLinks.confirmEmail, element: <ConfirmEmailScreen /> },
        { path: publicLinks.qbConnection, element: <QBConnectionCompleted /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["User.ManageOthers"]]} />,
      path: "admin",
      children: [{ path: adminToolLinks.users, element: <UsersManagementScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["User.ManageOthers"]]} />,
      path: "admin",
      children: [{ path: adminToolLinks.quickBooks, element: <QuickBooksScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["User.ManageSelf"]]} />,
      children: [{ path: technichianToolLinks.myProfile, element: <MyProfileScreenScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Company.Browse"]]} />,
      children: [
        {
          path: technichianToolLinks.companyList,
          element: <CompanyListScreen />,
        },
        { path: technichianToolLinks.companyMap, element: <MapScreen /> },
        { path: technichianToolLinks.companiesDetails, element: <CompanyDetailsScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Vehicle.Browse"]]} />,
      children: [{ path: technichianToolLinks.vehiclesList, element: <VehiclesListScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Vehicle.Manage"]]} />,
      children: [
        {
          path: technichianToolLinks.vehiclesCreate,
          element: <VehiclesAddEditScreen />,
        },
        {
          path: technichianToolLinks.vehiclesEdit,
          element: <VehiclesAddEditScreen />,
        },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Quote.Browse"]]} />,
      children: [
        { path: technichianToolLinks.quotesList, element: <QuotesListScreen /> },
        { path: technichianToolLinks.quotesDetails, element: <QuotesDetailsScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["WorkOrder.Browse"]]} />,
      children: [{ path: technichianToolLinks.schedule, element: <ScheduleScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Company.Manage"]]} />,
      children: [
        { path: technichianToolLinks.contactsCreate, element: <ContactsAddEditScreen /> },
        { path: technichianToolLinks.contactsEdit, element: <ContactsAddEditScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["WorkOrder.Browse"]]} />,
      children: [
        {
          path: technichianToolLinks.companySales,
          element: <SalesScreen />,
        },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Company.Manage"]]} />,
      children: [
        { path: technichianToolLinks.equipmentCreate, element: <CompanyEquipmentAddEditScreen /> },
        { path: technichianToolLinks.equipmentEdit, element: <CompanyEquipmentEditTabScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Company.Manage"]]} />,
      children: [
        { path: technichianToolLinks.notesCreate, element: <NotesAddEditScreen /> },
        { path: technichianToolLinks.notesEdit, element: <NotesAddEditScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Equipment.Browse"]]} />,
      children: [
        { path: technichianToolLinks.equipmentMakesList, element: <EquipmentMakesListScreen /> },
        { path: technichianToolLinks.equipmentTypesList, element: <EquipmentTypesListScreen /> },
        { path: technichianToolLinks.equipmentModelsList, element: <EquipmentModelsListScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["WorkOrder.Browse"]]} />,
      children: [
        { path: technichianToolLinks.workOrdersList, element: <WorkOrdersListScreen /> },
        { path: technichianToolLinks.workOrderDetails, element: <WorkOrdersDetailsScreen /> },
        { path: technichianToolLinks.workOrdersNotesCreate, element: <NotesAddEditScreen /> },
        { path: technichianToolLinks.workOrdersNotesEdit, element: <NotesAddEditScreen /> },
      ],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["TimeTracking.Browse"]]} />,
      children: [{ path: technichianToolLinks.payroll, element: <PayrollScreen /> }],
    },
    {
      element: <ProtectedRoute allowedPermissions={[UserPermissions["Settings.ManageTenant"]]} />,
      path: "admin",
      children: [{ path: adminToolLinks.settings, element: <SettingsScreen /> }],
    },
    {
      element: <AccessDeniedRoute />,
      path: publicLinks.forbidden,
    },
    {
      element: <Navigate to={redirectByRole(currentUser?.roles || [])} />,
      path: "/",
    },
    {
      element: <NotFoundRoute />,
      path: "*",
    },
  ]);
};

export default AppRoutes;
