import { API } from "constants/RestApi";
import { IAppSettingsTenant } from "core/types/entities/IAppSettings";
import { useBaseAction } from "./index";
import { ITimeZones } from "../../core/types/entities/IItem";

const useAppSettings = () => {
  const { request } = useBaseAction();
  const getAppSettings = async () => {
    return (await request({
      method: "GET",
      url: API.APP_SETTINGS_TENANT,
    })) as IAppSettingsTenant;
  };

  const getTimeZones = async () => {
    return (await request({
      method: "GET",
      url: API.APP_SETTINGS_TIMEZONES,
    })) as ITimeZones[];
  };

  return { getAppSettings, getTimeZones };
};

export default useAppSettings;
