// 1) Add Icon Name to Props
// 2) Add SVG Icon Code
// 3) Add SVG Icon to switch/case

import React from "react";

type TProps = {
  icon:
    | "arrowUp"
    | "arrowDown"
    | "threeDots"
    | "trash"
    | "pencil"
    | "arrowLeft"
    | "arrowLeftBig"
    | "arrowDownBig"
    | "plus"
    | "cancel"
    | "check"
    | "timer"
    | "clockTimer"
    | "start"
    | "pause"
    | "stop"
    | "cancelLine"
    | "pluseLine"
    | "reject"
    | "arrowRight"
    | "location"
    | "phone"
    | "email"
    | "warning"
    | "road"
    | "destination"
    | "checkLine"
    | "viewArrow"
    | "create"
    | "stopCircle"
    | "reset"
    | "eyes"
    | "officeTimer"
    | "print"
    | "triangle-alert"
    | "attachment";
  width?: number;
  height?: number;
  color?: string;
};

function Svg({ icon, width, height, color }: TProps) {
  const print = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      width={23}
      height={23}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
      />
    </svg>
  );

  const arrowUp = (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33248 4.38622C7.20758 4.51038 7.03861 4.58008 6.86248 4.58008C6.68636 4.58008 6.51739 4.51038 6.39249 4.38622L3.99915 2.02622L1.63915 4.38622C1.51424 4.51038 1.34528 4.58008 1.16915 4.58008C0.993028 4.58008 0.82406 4.51038 0.699152 4.38622C0.636666 4.32424 0.58707 4.25051 0.553224 4.16927C0.519378 4.08803 0.501953 4.00089 0.501953 3.91288C0.501953 3.82488 0.519378 3.73774 0.553224 3.6565C0.58707 3.57526 0.636666 3.50152 0.699152 3.43955L3.52582 0.612883C3.58779 0.550397 3.66153 0.500801 3.74277 0.466955C3.82401 0.43311 3.91114 0.415684 3.99915 0.415684C4.08716 0.415684 4.1743 0.43311 4.25554 0.466955C4.33678 0.500801 4.41051 0.550397 4.47249 0.612883L7.33248 3.43955C7.39497 3.50152 7.44457 3.57526 7.47841 3.6565C7.51226 3.73774 7.52968 3.82488 7.52968 3.91288C7.52968 4.00089 7.51226 4.08803 7.47841 4.16927C7.44457 4.25051 7.39497 4.32424 7.33248 4.38622Z"
        fill="#3D52B9"
      />
    </svg>
  );

  const TriangleAlerts = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      className="lucide lucide-triangle-alert text-warning"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3M12 9v4M12 17h.01" />
    </svg>
  );

  const officeTimer = (
    <svg
      height={height}
      width={width}
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      style={{ background: "--var(bg-primary)" }}
    >
      <style type="text/css">{`.st0{fill:${color};} `}</style>
      <g>
        <path
          className="st0"
          d="M329.39,123.391l-59.236,59.228l-59.22-59.228c-17.226,8.113-31.83,26.963-31.83,44.673v162.064
        c0,11.679,9.474,21.152,21.144,21.152c5.754,0,0,0,14.768,0l8.048,138.216c0,12.432,10.08,22.504,22.504,22.504
        c5.245,0,14.915,0,24.586,0c9.687,0,19.357,0,24.602,0c12.424,0,22.505-10.072,22.505-22.504l8.048-138.216
        c14.752,0,9.015,0,14.752,0c11.686,0,21.144-9.474,21.144-21.152V168.064C361.221,150.354,346.617,131.504,329.39,123.391z"
        />
        <path
          className="st0"
          d="M270.661,96.435c26.636,0,48.206-21.594,48.206-48.205c0-26.652-21.57-48.23-48.206-48.23
        c-26.651,0-48.222,21.578-48.222,48.23C222.44,74.841,244.011,96.435,270.661,96.435z"
        />
        <path
          className="st0"
          d="M193.167,362.581h-35.945c-3.558,0-6.426,2.868-6.426,6.425v104.639c0,3.556,2.868,6.425,6.426,6.425h35.945
        c3.54,0,6.425-2.868,6.425-6.425V369.006C199.591,365.45,196.706,362.581,193.167,362.581z"
        />
        <polygon
          className="st0"
          points="257.189,116.76 257.189,154.64 270.154,171.989 283.135,154.64 283.135,116.76 270.154,116.76"
        />
      </g>
    </svg>
  );

  const arrowDown = (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.332.614a.667.667 0 0 0-.94 0L4 2.974 1.64.614a.667.667 0 1 0-.94.946l2.827 2.827a.667.667 0 0 0 .946 0l2.86-2.827a.666.666 0 0 0 0-.946Z"
        fill="#3D52B9"
      />
    </svg>
  );

  const arrowDownBig = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      style={{ transform: "rotate(90deg)" }}
    >
      <path
        fill="#3D52B9"
        d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12Z"
      />
    </svg>
  );

  const arrowLeftBig = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
      <path
        fill="#3D52B9"
        d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12Z"
      />
    </svg>
  );

  const arrowLeft = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
        fill="black"
      />
    </svg>
  );

  const threeDots = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-three-dots"
      viewBox="0 0 16 16"
    >
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  );

  const trash = (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path
        fill={color ?? "#DC3545"}
        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
      />
    </svg>
  );

  const pencil = (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z" />
    </svg>
  );

  const plus = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM9 4.5C9 3.94771 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94771 7 4.5V7H4.5C3.94771 7 3.5 7.44772 3.5 8C3.5 8.55228 3.94771 9 4.5 9H7V11.5C7 12.0523 7.44772 12.5 8 12.5C8.55228 12.5 9 12.0523 9 11.5V9H11.5C12.0523 9 12.5 8.55228 12.5 8C12.5 7.44772 12.0523 7 11.5 7H9V4.5Z"
        fill={color ?? "#4bbf73"}
        stroke={color ?? "#4bbf73"}
        strokeLinejoin="round"
      />
    </svg>
  );

  const cancel = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
        fill={color ?? "#D9534F"}
      />
    </svg>
  );

  const check = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
        fill={color ?? "#4BBF73"}
      />
    </svg>
  );

  const timer = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49999 5.59997C8.49999 5.32383 8.27613 5.09998 7.99999 5.09998C7.72384 5.09998 7.49999 5.32383 7.49999 5.59998L7.5 8.5H4.5C4.22386 8.5 4 8.72386 4 9C4 9.27614 4.22386 9.5 4.5 9.5H8C8.13261 9.5 8.25979 9.44732 8.35355 9.35355C8.44732 9.25978 8.5 9.13261 8.5 9L8.49999 5.59997Z"
        fill={color ?? "#989898"}
      />
      <path
        d="M6.5 1C6.5 0.723858 6.72386 0.5 7 0.5H9C9.27614 0.5 9.5 0.723858 9.5 1C9.5 1.27614 9.27614 1.5 9 1.5V2.07094C10.3599 2.26553 11.5936 2.85083 12.5838 3.70947C12.5879 3.70514 12.592 3.70087 12.5962 3.69665L12.9498 3.34309L12.5962 2.98954C12.401 2.79428 12.401 2.4777 12.5962 2.28243C12.7915 2.08717 13.1081 2.08717 13.3033 2.28243L14.7175 3.69665C14.9128 3.89191 14.9128 4.20849 14.7175 4.40375C14.5223 4.59902 14.2057 4.59902 14.0104 4.40375L13.6569 4.0502L13.3033 4.40375C13.2991 4.40797 13.2948 4.41208 13.2905 4.41611C14.3555 5.64417 15 7.24677 15 9C15 12.866 11.866 16 8 16C4.13401 16 1 12.866 1 9C1 5.47347 3.60749 2.55639 7 2.07094V1.5C6.72386 1.5 6.5 1.27614 6.5 1ZM8 3C7.84443 3 7.69034 3.00591 7.53793 3.0175C4.44038 3.25314 2 5.84182 2 9C2 12.3137 4.68629 15 8 15C11.3137 15 14 12.3137 14 9C14 5.84182 11.5596 3.25314 8.46207 3.0175C8.30966 3.00591 8.15557 3 8 3Z"
        fill={color ?? "#989898"}
      />
    </svg>
  );

  const clockTimer = (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.51555 7C3.55827 8.4301 3 10.1499 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3V6M12 12L8 8"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const start = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color ?? "#black"}
      />
      <path
        d="M6.27121 5.05542C6.43774 4.96972 6.63821 4.98427 6.79062 5.09313L10.2906 7.59313C10.422 7.68699 10.5 7.83853 10.5 8C10.5 8.16148 10.422 8.31301 10.2906 8.40687L6.79062 10.9069C6.63821 11.0157 6.43774 11.0303 6.27121 10.9446C6.10467 10.8589 6 10.6873 6 10.5V5.5C6 5.31271 6.10467 5.14112 6.27121 5.05542Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const pause = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color ?? "#black"}
      />
      <path
        d="M5 6.25C5 5.55964 5.55964 5 6.25 5C6.94036 5 7.5 5.55964 7.5 6.25V9.75C7.5 10.4404 6.94036 11 6.25 11C5.55964 11 5 10.4404 5 9.75V6.25ZM8.5 6.25C8.5 5.55964 9.05964 5 9.75 5C10.4404 5 11 5.55964 11 6.25V9.75C11 10.4404 10.4404 11 9.75 11C9.05964 11 8.5 10.4404 8.5 9.75V6.25Z"
        fill={color ?? "#black"}
      />
    </svg>
  );
  const stop = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color ?? "#black"}
      />
      <path
        d="M5 6.5C5 5.67157 5.67157 5 6.5 5H9.5C10.3284 5 11 5.67157 11 6.5V9.5C11 10.3284 10.3284 11 9.5 11H6.5C5.67157 11 5 10.3284 5 9.5V6.5Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const cancelLine = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L8 7.29289L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L8.70711 8L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L8 8.70711L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95119 13.6583 1.95119 13.3417 2.14645 13.1464L7.29289 8L2.14645 2.85355Z"
        fill={color ?? "#black"}
        stroke={color ?? "#black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const pluseLine = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C7.27614 1 7.5 1.22386 7.5 1.5V6.5H12.5C12.7761 6.5 13 6.72386 13 7C13 7.27614 12.7761 7.5 12.5 7.5H7.5V12.5C7.5 12.7761 7.27614 13 7 13C6.72386 13 6.5 12.7761 6.5 12.5V7.5H1.5C1.22386 7.5 1 7.27614 1 7C1 6.72386 1.22386 6.5 1.5 6.5H6.5V1.5C6.5 1.22386 6.72386 1 7 1Z"
        fill={color ?? "#black"}
        stroke={color ?? "#black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const reject = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        fill={color ?? "#black"}
      />
      <path
        d="M12.3536 5.64645C12.1583 5.45118 11.8417 5.45118 11.6464 5.64645L5.64645 11.6464C5.45118 11.8417 5.45118 12.1583 5.64645 12.3536C5.84171 12.5488 6.15829 12.5488 6.35355 12.3536L12.3536 6.35355C12.5488 6.15829 12.5488 5.84171 12.3536 5.64645Z"
        fill={color ?? "#black"}
      />
      <path
        d="M9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke={color ?? "#black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3536 5.64645C12.1583 5.45118 11.8417 5.45118 11.6464 5.64645L5.64645 11.6464C5.45118 11.8417 5.45118 12.1583 5.64645 12.3536C5.84171 12.5488 6.15829 12.5488 6.35355 12.3536L12.3536 6.35355C12.5488 6.15829 12.5488 5.84171 12.3536 5.64645Z"
        stroke={color ?? "#black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const arrowRight = (
    <svg width="35" height="35" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88795 9.5625C3.88795 9.25184 4.27924 9 4.7619 9H25.3747L19.875 5.46025C19.5337 5.24058 19.5337 4.88442 19.875 4.66475C20.2163 4.44508 20.7697 4.44508 21.111 4.66475L28.1026 9.16475C28.4439 9.38442 28.4439 9.74058 28.1026 9.96025L21.111 14.4602C20.7697 14.6799 20.2163 14.6799 19.875 14.4602C19.5337 14.2406 19.5337 13.8844 19.875 13.6648L25.3747 10.125H4.7619C4.27924 10.125 3.88795 9.87316 3.88795 9.5625Z"
        fill="black"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const location = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C8 16 14 10.3137 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6C2 10.3137 8 16 8 16ZM8 9C6.34315 9 5 7.65685 5 6C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6C11 7.65685 9.65685 9 8 9Z"
        fill={color ?? "#FF0000"}
      />
    </svg>
  );

  const phone = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65387 1.32849C3.40343 1.00649 2.92745 0.976861 2.639 1.26531L1.60508 2.29923C1.1216 2.78271 0.94387 3.46766 1.1551 4.06847C2.00338 6.48124 3.39215 8.74671 5.32272 10.6773C7.25329 12.6078 9.51876 13.9966 11.9315 14.8449C12.5323 15.0561 13.2173 14.8784 13.7008 14.3949L14.7347 13.361C15.0231 13.0726 14.9935 12.5966 14.6715 12.3461L12.3653 10.5524C12.2008 10.4245 11.9866 10.3793 11.7845 10.4298L9.59541 10.9771C9.00082 11.1257 8.37183 10.9515 7.93845 10.5181L5.48187 8.06155C5.04849 7.62817 4.87427 6.99919 5.02292 6.40459L5.57019 4.21553C5.62073 4.01336 5.57552 3.79918 5.44758 3.63468L3.65387 1.32849ZM1.88477 0.511076C2.62689 -0.231039 3.8515 -0.154797 4.49583 0.673634L6.28954 2.97983C6.6187 3.40304 6.73502 3.95409 6.60498 4.47423L6.05772 6.66329C5.99994 6.8944 6.06766 7.13888 6.2361 7.30732L8.69268 9.7639C8.86113 9.93235 9.1056 10.0001 9.33671 9.94229L11.5258 9.39502C12.0459 9.26499 12.597 9.3813 13.0202 9.71047L15.3264 11.5042C16.1548 12.1485 16.231 13.3731 15.4889 14.1152L14.455 15.1492C13.7153 15.8889 12.6089 16.2137 11.5778 15.8512C9.01754 14.9511 6.61438 13.4774 4.56849 11.4315C2.5226 9.38562 1.04895 6.98246 0.148838 4.42225C-0.213682 3.39112 0.11113 2.28472 0.85085 1.545L1.88477 0.511076Z"
        fill={color ?? "black"}
      />
    </svg>
  );

  const email = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 2.89543 0.895431 2 2 2H14C15.1046 2 16 2.89543 16 4V12C16 13.1046 15.1046 14 14 14H2C0.895431 14 0 13.1046 0 12V4ZM2 3C1.44772 3 1 3.44772 1 4V4.2169L8 8.4169L15 4.2169V4C15 3.44772 14.5523 3 14 3H2ZM15 5.3831L10.2919 8.20794L15 11.1052V5.3831ZM14.9662 12.2586L9.32583 8.7876L8 9.5831L6.67417 8.7876L1.03376 12.2586C1.14774 12.6855 1.53715 13 2 13H14C14.4628 13 14.8523 12.6855 14.9662 12.2586ZM1 11.1052L5.70808 8.20794L1 5.3831V11.1052Z"
        fill="black"
      />
    </svg>
  );

  const warning = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.93842 2.0157C7.95578 2.00605 7.97748 2 8.00154 2C8.02561 2 8.04731 2.00605 8.06467 2.0157C8.07966 2.02403 8.09948 2.03926 8.11939 2.07312L14.9763 13.7398C15.012 13.8005 15.0113 13.864 14.9785 13.9231C14.962 13.9528 14.9415 13.9724 14.9238 13.9833C14.909 13.9925 14.8901 14 14.8585 14H1.14459C1.11303 14 1.09404 13.9925 1.07932 13.9833C1.06163 13.9724 1.04106 13.9528 1.02461 13.9231C0.991803 13.864 0.991085 13.8005 1.02675 13.7398L7.8837 2.07312C7.9036 2.03926 7.92343 2.02403 7.93842 2.0157ZM8.98151 1.56642C8.53763 0.811193 7.46546 0.811193 7.02158 1.56642L0.164626 13.2331C-0.292407 14.0107 0.256279 15 1.14459 15H14.8585C15.7468 15 16.2955 14.0107 15.8385 13.2331L8.98151 1.56642Z"
        fill={color ?? "#black"}
      />
      <path
        d="M7.00154 12C7.00154 11.4477 7.44926 11 8.00154 11C8.55383 11 9.00154 11.4477 9.00154 12C9.00154 12.5523 8.55383 13 8.00154 13C7.44926 13 7.00154 12.5523 7.00154 12Z"
        fill={color ?? "#black"}
      />
      <path
        d="M7.0995 5.99504C7.04623 5.46228 7.46459 5 8 5C8.53541 5 8.95377 5.46228 8.9005 5.99504L8.54975 9.50248C8.52151 9.78492 8.28384 10 8 10C7.71616 10 7.47849 9.78492 7.45025 9.50248L7.0995 5.99504Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const road = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.5C5 7.11929 6.11929 6 7.5 6H9V4.53376C9 4.3218 9.24721 4.20601 9.41005 4.3417L11.7695 6.30794C11.8895 6.40789 11.8895 6.59211 11.7695 6.69206L9.41005 8.65829C9.24721 8.79399 9 8.6782 9 8.46624V7H7.5C6.67157 7 6 7.67157 6 8.5V11H5V8.5Z"
        fill={color ?? "#black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95059 0.434678C7.53017 -0.144894 8.46984 -0.144892 9.04941 0.434679L15.5653 6.95059C16.1449 7.53017 16.1449 8.46984 15.5653 9.04941L9.04941 15.5653C8.46983 16.1449 7.53016 16.1449 6.95059 15.5653L0.434678 9.04941C-0.144894 8.46983 -0.144892 7.53016 0.434679 6.95059L6.95059 0.434678ZM8.3498 1.13428C8.15661 0.941093 7.84339 0.941093 7.6502 1.13428L1.13428 7.6502C0.941093 7.84339 0.941093 8.15661 1.13428 8.3498L7.6502 14.8657C7.84339 15.0589 8.15661 15.0589 8.3498 14.8657L14.8657 8.3498C15.0589 8.15661 15.0589 7.84339 14.8657 7.6502L8.3498 1.13428Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const destination = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1 11.2C3.19443 11.0741 3.34262 11 3.5 11H6C6.27615 11 6.5 11.2239 6.5 11.5C6.5 11.7761 6.27615 12 6 12H3.75L1.5 15H14.5L12.25 12H10C9.72386 12 9.5 11.7761 9.5 11.5C9.5 11.2239 9.72386 11 10 11H12.5C12.6574 11 12.8056 11.0741 12.9 11.2L15.9 15.2C16.0136 15.3515 16.0319 15.5542 15.9472 15.7236C15.8625 15.893 15.6894 16 15.5 16H0.500003C0.310617 16 0.137485 15.893 0.0527893 15.7236C-0.0319067 15.5542 -0.0136288 15.3515 0.100003 15.2L3.1 11.2Z"
        fill={color ?? "#black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7C9.65686 7 11 5.65685 11 4C11 2.34315 9.65686 1 8 1ZM4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.03981 10.4732 7.723 8.5 7.96905V13.5C8.5 13.7761 8.27615 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5V7.96905C5.52685 7.723 4 6.03981 4 4Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const checkLine = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96963 0.96967C7.26253 0.676777 7.7374 0.676777 8.03029 0.96967C8.31959 1.25897 8.32314 1.72582 8.04094 2.01947L4.04873 7.00973C4.04297 7.01693 4.03682 7.02381 4.03029 7.03033C3.7374 7.32322 3.26253 7.32322 2.96963 7.03033L0.323186 4.38388C0.0302923 4.09099 0.0302923 3.61612 0.323186 3.32322C0.616079 3.03033 1.09095 3.03033 1.38385 3.32322L3.47737 5.41674L6.94974 0.992105C6.95592 0.984235 6.96256 0.976743 6.96963 0.96967Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const viewArrow = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM4.5 7.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H10.2929L8.14645 10.6464C7.95118 10.8417 7.95118 11.1583 8.14645 11.3536C8.34171 11.5488 8.65829 11.5488 8.85355 11.3536L11.8536 8.35355C12.0488 8.15829 12.0488 7.84171 11.8536 7.64645L8.85355 4.64645C8.65829 4.45118 8.34171 4.45118 8.14645 4.64645C7.95118 4.84171 7.95118 5.15829 8.14645 5.35355L10.2929 7.5H4.5Z"
        fill={color ?? "black"}
      />
    </svg>
  );

  const create = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5016 1.93934C15.6969 2.1346 15.6969 2.45118 15.5016 2.64645L14.4587 3.68933L12.4587 1.68933L13.5016 0.646447C13.6969 0.451184 14.0134 0.451185 14.2087 0.646447L15.5016 1.93934Z"
        fill={color ?? "#black"}
      />
      <path
        d="M13.7516 4.39644L11.7516 2.39644L4.93861 9.20943C4.88372 9.26432 4.84237 9.33123 4.81782 9.40487L4.01326 11.8186C3.94812 12.014 4.13405 12.1999 4.32949 12.1348L6.74317 11.3302C6.81681 11.3057 6.88372 11.2643 6.93861 11.2094L13.7516 4.39644Z"
        fill={color ?? "#black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V7.5C15 7.22386 14.7761 7 14.5 7C14.2239 7 14 7.22386 14 7.5V13.5C14 13.7761 13.7761 14 13.5 14H2.5C2.22386 14 2 13.7761 2 13.5V2.5C2 2.22386 2.22386 2 2.5 2H9C9.27614 2 9.5 1.77614 9.5 1.5C9.5 1.22386 9.27614 1 9 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5Z"
        fill={color ?? "#black"}
      />
    </svg>
  );

  const stopCircle = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color ?? "black"}
      />
      <path
        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
        fill={color ?? "black"}
      />
    </svg>
  );

  const reset = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C10.7614 3 13 5.23857 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 7.25457 3.1628 6.54852 3.45431 5.91433C3.56963 5.66343 3.45973 5.36654 3.20882 5.25121C2.95792 5.13588 2.66102 5.24578 2.54569 5.49669C2.19519 6.25923 2 7.1075 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2V3Z"
        fill="black"
      />
      <path
        d="M8 4.46624V0.533757C8 0.321798 7.75279 0.206009 7.58995 0.341702L5.23047 2.30794C5.11053 2.40789 5.11053 2.5921 5.23047 2.69205L7.58995 4.65829C7.75279 4.79399 8 4.6782 8 4.46624Z"
        fill="black"
      />
    </svg>
  );

  const eyes = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 8 13 2.5 8 2.5C3 2.5 0 8 0 8C0 8 3 13.5 8 13.5C13 13.5 16 8 16 8ZM1.1727 8C1.22963 7.91321 1.29454 7.81677 1.36727 7.71242C1.70216 7.23193 2.19631 6.5929 2.83211 5.95711C4.12103 4.66818 5.88062 3.5 8 3.5C10.1194 3.5 11.879 4.66818 13.1679 5.95711C13.8037 6.5929 14.2978 7.23193 14.6327 7.71242C14.7055 7.81677 14.7704 7.91321 14.8273 8C14.7704 8.08679 14.7055 8.18323 14.6327 8.28758C14.2978 8.76807 13.8037 9.4071 13.1679 10.0429C11.879 11.3318 10.1194 12.5 8 12.5C5.88062 12.5 4.12103 11.3318 2.83211 10.0429C2.19631 9.4071 1.70216 8.76807 1.36727 8.28758C1.29454 8.18323 1.22963 8.08679 1.1727 8Z"
        fill={color ?? "black"}
      />
      <path
        d="M8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5ZM4.5 8C4.5 6.067 6.067 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.067 11.5 4.5 9.933 4.5 8Z"
        fill={color ?? "black"}
      />
    </svg>
  );

  const attachment = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 3C4.5 1.61929 5.61929 0.5 7 0.5C8.38071 0.5 9.5 1.61929 9.5 3V12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12V5C6.5 4.72386 6.72386 4.5 7 4.5C7.27614 4.5 7.5 4.72386 7.5 5V12C7.5 12.2761 7.72386 12.5 8 12.5C8.27614 12.5 8.5 12.2761 8.5 12V3C8.5 2.17157 7.82843 1.5 7 1.5C6.17157 1.5 5.5 2.17157 5.5 3V12C5.5 13.3807 6.61929 14.5 8 14.5C9.38071 14.5 10.5 13.3807 10.5 12V5C10.5 4.72386 10.7239 4.5 11 4.5C11.2761 4.5 11.5 4.72386 11.5 5V12C11.5 13.933 9.933 15.5 8 15.5C6.067 15.5 4.5 13.933 4.5 12V3Z"
        fill={color ?? "black"}
      />
    </svg>
  );

  switch (icon) {
    case "officeTimer":
      return officeTimer;
    case "arrowUp":
      return arrowUp;
    case "triangle-alert":
      return TriangleAlerts;
    case "arrowDown":
      return arrowDown;
    case "threeDots":
      return threeDots;
    case "trash":
      return trash;
    case "pencil":
      return pencil;
    case "arrowLeft":
      return arrowLeft;
    case "arrowLeftBig":
      return arrowLeftBig;
    case "arrowDownBig":
      return arrowDownBig;
    case "plus":
      return plus;
    case "cancel":
      return cancel;
    case "check":
      return check;
    case "timer":
      return timer;
    case "clockTimer":
      return clockTimer;
    case "start":
      return start;
    case "pause":
      return pause;
    case "stop":
      return stop;
    case "cancelLine":
      return cancelLine;
    case "pluseLine":
      return pluseLine;
    case "reject":
      return reject;
    case "arrowRight":
      return arrowRight;
    case "location":
      return location;
    case "phone":
      return phone;
    case "email":
      return email;
    case "warning":
      return warning;
    case "road":
      return road;
    case "destination":
      return destination;
    case "checkLine":
      return checkLine;
    case "viewArrow":
      return viewArrow;
    case "create":
      return create;
    case "stopCircle":
      return stopCircle;
    case "reset":
      return reset;
    case "eyes":
      return eyes;
    case "print":
      return print;
    case "attachment":
      return attachment;
    default:
      return null;
  }
}

export default Svg;
