import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IBaseListResponse } from "core/types/base";
import useBaseAction from "./useBaseActions";

type TProps = {
  baseUrl?: string;
  orderby?: string;
  expand?: string;
  filter?: string;
};

export type TFetchListProps = {
  useLoader?: boolean;
  filter?: string;
  page?: number;
  isClear?: boolean;
  pageSize?: number;
};

export const defaultPageSize = 12;

const useInfinityList = <T extends unknown>({
  baseUrl,
  orderby,
  expand,
  filter: defaultFilterQuery,
}: TProps) => {
  const { request } = useBaseAction();
  const [listData, setListData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(false);

  const [storedFilterQuery, setStoredFilterQuery] = useState<string>("");

  const [searchParams] = useSearchParams();
  const urlParamFilter = searchParams.get("filter");

  const fetchListData = async ({
    useLoader = false,
    filter: appliedFilterQuery,
    page,
    isClear = false,
    pageSize,
  }: TFetchListProps) => {
    if (fetching) return;
    let finalFilter = storedFilterQuery;
    if (isClear) {
      finalFilter = "";
    } else if (appliedFilterQuery !== undefined) {
      finalFilter = appliedFilterQuery;
    }

    setFetching(true);

    const filterQueryWithParams = [defaultFilterQuery, finalFilter, !isClear && urlParamFilter]
      .filter(Boolean)
      .join(" and ");

    const skipValue =
      page !== undefined
        ? page * (pageSize || defaultPageSize)
        : currentPage * (pageSize || defaultPageSize);

    const response = (await request({
      method: "GET",
      url: baseUrl || "",
      params: {
        $orderby: orderby || null,
        $count: true,
        $top: pageSize || defaultPageSize,
        $skip: skipValue,
        $filter: filterQueryWithParams?.replaceAll("and  and", "and") || null,
        $expand: expand || null,
      },
      useLoader,
    })) as IBaseListResponse<T>;

    const { items, count } = response;

    const isFirstPage = page === 0 || currentPage === 0;
    setListData(isFirstPage ? items : [...listData, ...items]);
    setTotalCount(count);

    const nextPage = page !== undefined ? page + 1 : currentPage + 1;
    setCurrentPage(nextPage);

    setHasMore(nextPage * (pageSize || defaultPageSize) < count);

    setFetching(false);

    if (appliedFilterQuery !== undefined && !isClear) {
      setStoredFilterQuery(appliedFilterQuery);
    }
    if (isClear) {
      setStoredFilterQuery("");
    }
  };

  const filterList = (id: number) => {
    const filteredList = listData.filter((data: any) => data?.id !== id);
    setListData(filteredList);
    setTotalCount((prev) => prev - 1);
  };

  const changeItem = (id: number, key: string, value: any) => {
    const changedList = listData.map((data: any) =>
      data?.id === id ? { ...data, [key]: value } : data,
    );
    setListData(changedList);
  };

  return {
    fetchListData,
    listData,
    totalCount,
    hasMore,
    fetching,
    currentPage,
    filterList,
    changeItem,
  };
};

export default useInfinityList;
