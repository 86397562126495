/* eslint no-nested-ternary: "off" */
import { adminToolLinks, publicLinks, technichianToolLinks } from "constants/AppLinks";
import navbarMenuItems, { navbarMenuItemsAdmin } from "constants/NavbarMenuItems";
import { useAppSelector } from "core/helpers";
import { UserPermissions } from "core/types/enums/PermissionsEnum";
import { useAuth, useUserRole } from "hooks/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useSubWorkOrderItem from "hooks/admin/WO/useSubWorkOrderItem";
import Logo from "assets/images/logo.png";
import "./Navbar.scss";
// import { nanoid } from "nanoid";
import { useMediaQuery } from "@react-hook/media-query";
import useOfficeTimer from "hooks/common/useOfficeTimer";
import { ILocalTimer } from "core/types/entities/IExpense";
import Svg from "../SVG/Svg";

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const { currentUser, timers } = useAppSelector((state) => state.app);
  const { checkTimersNew } = useSubWorkOrderItem();
  const { signOut } = useAuth();
  const { permissionGranted } = useUserRole();
  const location = useLocation();
  const navbarColor = useRef<HTMLDivElement | null>(null);
  const dropdownManage = useRef<HTMLUListElement | null>(null);
  const dropdownAdmin = useRef<HTMLUListElement | null>(null);
  const toggleButton = useRef<HTMLButtonElement | null>(null);
  const [renderTimer, setRenderTimer] = useState<number>(0);
  const [offline, setOffline] = useState(false);
  const matches = useMediaQuery("only screen and (min-width: 992px) and (max-width: 1400px)");
  const matchesXl = useMediaQuery("only screen and (min-width: 1300px)");
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");
  const { toggleOfficeTimer } = useOfficeTimer();
  // const { getFromLs } = useLSActions();
  const [officeTimer, setOfficeTimer] = useState<ILocalTimer | undefined>();

  // const getOfficeTimer = () => {
  //   const storage = timers.find(
  //     (timer) => (timer as any)?.extensionData?.timerType === "officeTimer",
  //   );
  //   // getFromLs("officeTimer").then((data: unknown) =>
  //   //   setOfficeTimer(storage || JSON.parse(data as string)),
  //   // );
  //   setOfficeTimer(storage);
  // };

  const getOfficeTimerNew = () => {
    const storage = timers.find(
      (timer) => (timer as any)?.extensionData?.timerType === "officeTimer",
    );
    setOfficeTimer(storage);
  };

  useEffect(() => {
    // getOfficeTimer();
    getOfficeTimerNew();
  }, [timers]);

  const checkActiveDropdown = (path: string): string => {
    return location.pathname.includes(path) ? "link-light" : "";
  };

  const closeNavbar = () => {
    const navbar = navbarColor.current?.classList;
    if (navbar) {
      navbar.remove("show");
    }
  };

  const closeDropDown = () => {
    const navbarUser = dropdownManage.current?.classList;
    const navbarAdmin = dropdownAdmin.current?.classList;
    if (navbarUser) {
      navbarUser.remove("show");
    }
    if (navbarAdmin) {
      navbarAdmin.remove("show");
    }
  };

  const changeToggle = (init = false) => {
    const toggle = toggleButton.current?.classList;
    if (toggle) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      toggle.contains("change-toggle") || init
        ? toggle.remove("change-toggle")
        : toggle.add("change-toggle");
    }
  };

  const handleOfficeTime = () => {
    if ((officeTimer?.extensionData as any)?.timerType === "officeTimer") {
      toggleOfficeTimer(
        {
          note: "",
          startTime: officeTimer?.startTime as string,
          id: (officeTimer as any)?.id,
          rowVersion: (officeTimer as any)?.rowVersion,
        },
        true,
        async () => {
          await checkTimersNew();
          setOfficeTimer(undefined);
        },
      );
      return;
    }
    toggleOfficeTimer(
      {
        note: "",
        endTime: null,
      },
      false,
      async () => {
        await checkTimersNew();
      },
    );
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timers.length) {
      interval = setInterval(() => {
        setRenderTimer(Math.random());
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timers]);

  useEffect(() => {
    closeNavbar();
    closeDropDown();
    changeToggle(true);
  }, [location]);

  useEffect(() => {
    window.addEventListener("offline", () => setOffline(true));
    return () => {
      window.removeEventListener("offline", () => setOffline(false));
    };
  }, []);

  useEffect(() => {
    window.addEventListener("online", () => setOffline(false));
    return () => {
      window.removeEventListener("offline", () => setOffline(false));
    };
  }, []);
  const liRef = useRef<HTMLLIElement | null>(null);

  const getWOTimer = () => {
    return timers.filter((timer) => (timer?.extensionData as any)?.workOrderId)[0] as any;
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <div className="d-flex align-items-center">
          <Link className="navbar-brand me-1" to="/">
            <img src={Logo} className="logo" alt="Brand Logo" />
          </Link>
          {getWOTimer()?.extensionData.active && (
            <Link
              to={`/work-orders/${getWOTimer()?.extensionData.workOrderId}`}
              key={renderTimer}
              className="me-1 ms-1"
            >
              <span className="active-timer text-white">
                <Svg icon="clockTimer" width={30} height={30} />
              </span>
            </Link>
          )}
          {currentUser && (
            <button
              type="button"
              className="d-inline bg-primary border-0 "
              onClick={() => !isMobile && handleOfficeTime()}
              title={`${officeTimer ? "Stop" : "Start"} Office Timer`}
            >
              {Svg({
                icon: "officeTimer",
                color: officeTimer?.key ? "#4BBF73" : "#D9534F",
                width: 30,
                height: 30,
              })}
            </button>
          )}
          {offline && (!matches || matchesXl) && (
            <div className="nav-item text-danger">
              <div className="d-block nav-link text-decoration-none text-danger">Offline</div>
            </div>
          )}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor01"
          aria-controls="navbarColor01"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="toggleButton"
          ref={toggleButton}
          onClick={() => changeToggle()}
        >
          <span className="icon-bar top-bar" />
          <span className="icon-bar middle-bar" />
          <span className="icon-bar bottom-bar" />
        </button>
        <div className="collapse navbar-collapse" id="navbarColor01" ref={navbarColor}>
          <ul className="navbar-nav ms-auto align-items-start">
            {offline && matches && !matchesXl && (
              <li className="nav-item text-danger">
                <div className="d-block nav-link text-decoration-none text-danger">Offline</div>
              </li>
            )}
            {currentUser &&
              navbarMenuItems.map((menuItem) =>
                permissionGranted(menuItem.permissions) ? (
                  menuItem.type === "dropdown" ? (
                    <li
                      key={menuItem.title}
                      className={`nav-item ${
                        menuItem.visible ? "d-flex" : "d-none"
                      } align-items-center`}
                    >
                      <div className="dropdown text-lg-end d-flex flex-column flex-lg-row align-items-lg-center">
                        <a
                          href="!#"
                          className={`d-block nav-link text-decoration-none dropdown-toggle ${checkActiveDropdown(
                            "equipment",
                          )}`}
                          id="dropdownUser1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {menuItem.title}
                        </a>
                        <ul
                          className="dropdown-menu text-small navbar-dark bg-primary"
                          aria-labelledby="dropdownUser1"
                          id="dropdownManage"
                          ref={dropdownManage}
                        >
                          {menuItem.dropdownItems?.map((dropdownItem) => (
                            <div key={dropdownItem.title}>
                              <li>
                                <Link className="nav-link" to={dropdownItem.link}>
                                  {dropdownItem.title}
                                </Link>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ) : isMobile ? (
                    menuItem.link === "timer" && currentUser ? (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
                      <li
                        key={menuItem.title}
                        className="nav-item"
                        onClick={handleOfficeTime}
                        ref={liRef}
                      >
                        <div className="nav-link">
                          {officeTimer ? "Stop" : "Start"} Office Timer
                        </div>
                      </li>
                    ) : (
                      <li key={menuItem.title} className="nav-item">
                        <NavLink className="nav-link" to={menuItem.link ? menuItem.link : ""}>
                          {menuItem.title}
                        </NavLink>
                      </li>
                    )
                  ) : menuItem?.isMobile ? null : (
                    <li key={menuItem.title} className="nav-item">
                      <NavLink className="nav-link" to={menuItem.link ? menuItem.link : ""}>
                        {menuItem.title}
                      </NavLink>
                    </li>
                  )
                ) : null,
              )}
            {!currentUser && (
              <li className="nav-item">
                <NavLink className="nav-link" to={`/${publicLinks.singin}`}>
                  Sign In
                </NavLink>
              </li>
            )}
            {currentUser && (
              <li className="nav-item d-flex align-items-center">
                <div className="dropdown text-lg-end d-flex flex-column flex-lg-row align-items-lg-center">
                  <a
                    href="!#"
                    className={`d-block nav-link text-decoration-none dropdown-toggle ${checkActiveDropdown(
                      "admin",
                    )}`}
                    id="dropdownUser2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {currentUser.firstName} {currentUser.lastName}
                  </a>
                  <ul
                    className="dropdown-menu text-small navbar-dark bg-primary p-0"
                    aria-labelledby="dropdownUser2"
                    id="dropdownAdmin"
                    ref={dropdownAdmin}
                  >
                    <li>
                      <ul className="p-0 border-bottom-grey">
                        {navbarMenuItemsAdmin.dropdownItems?.map((dropdownItem) => (
                          <div key={dropdownItem.title}>
                            <li className="list-style-type-none px-1">
                              <Link className="nav-link" to={dropdownItem.link}>
                                {dropdownItem.title}
                              </Link>
                            </li>
                          </div>
                        ))}
                      </ul>
                    </li>
                    <li>
                      {permissionGranted([UserPermissions["User.ManageOthers"]]) ? (
                        <Link className="nav-link mx-1" to={`/admin/${adminToolLinks.users}`}>
                          Manage Users
                        </Link>
                      ) : (
                        <Link className="nav-link mx-1" to={`/${technichianToolLinks.myProfile}`}>
                          My Profile
                        </Link>
                      )}
                    </li>
                    {permissionGranted([UserPermissions["Settings.ManageTenant"]]) && (
                      <li>
                        <Link className="nav-link mx-1" to={`/admin/${adminToolLinks.quickBooks}`}>
                          Quick Books Status
                        </Link>
                      </li>
                    )}
                    {permissionGranted([UserPermissions["Settings.ManageTenant"]]) && (
                      <li>
                        <Link className="nav-link mx-1" to={`/admin/${adminToolLinks.settings}`}>
                          Settings
                        </Link>
                      </li>
                    )}
                    <li>
                      <a
                        className="nav-link mx-1"
                        href="!#"
                        onClick={(e) => {
                          e.preventDefault();
                          signOut();
                        }}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
