import { API } from "constants/RestApi";
import { ILocalTimer } from "core/types/entities/IExpense";
import { useDispatch } from "react-redux";
import { PayrollFormDataEvent } from "components/common/Modals/CreateEditOfficeTimeModal";
import { useBaseAction, useLSActions, useUserRole } from "../core";
import { selectTimers, setTimer } from "../../store/slices/appSlicer";
import { useAppSelector } from "../../core/helpers";

export interface TimerData extends Omit<PayrollFormDataEvent, "endTime" | "startTime" | "user"> {
  endTime?: string | null | Date;
  startTime?: string;
  rowVersion?: string;
}

export type TOfficeTimerPayload = {
  startTime?: string;
  endTime: string;
  timerKey?: string;
  userId?: string;
  note?: string;
};

const useOfficeTimer = () => {
  const dispatch = useDispatch();
  const { request } = useBaseAction();
  const timers = useAppSelector(selectTimers);
  const { removeItemFromLs } = useLSActions();
  const { currentUser } = useUserRole();

  const startOfficeTimer = async (payload: ILocalTimer) => {
    await request(
      {
        method: "POST",
        url: API.WORK_ORDERS_TIMERS,
        successMessage: "Shop timer has been started",
        useLoader: false,
        body: payload,
      },
      async (data) => {
        dispatch(setTimer([...timers, data]));
        // setToLs("officeTimer", JSON.stringify(payload));
      },
    );
  };

  const stopOfficeTimer = async (payload: TOfficeTimerPayload) => {
    await request(
      {
        method: "POST",
        url: API.OFFICE_TIMERS,
        successMessage: "Shop timer has been stopped",
        useLoader: false,
        body: payload,
      },
      async () => {
        const newData = timers.filter((elem) => elem?.key !== payload.timerKey);
        dispatch(setTimer(newData));
        removeItemFromLs("officeTimer");
      },
    );
  };

  const toggleOfficeTimer = async (
    data: TimerData,
    isEditMode?: boolean,
    callback?: () => void,
  ) => {
    const currentDate = new Date();
    const body = {
      note: data?.note || "",
      startTime: currentDate,
      endTime: null,
      userId: currentUser?.id,
    };
    const updateActionBody = {
      ...body,
      id: +(data?.id ?? 0),
      rowVersion: data?.rowVersion,
      endTime: currentDate,
      startTime: new Date(data?.startTime ?? "") ?? "",
    };
    await request({
      method: isEditMode ? "PUT" : "POST",
      url: API.OFFICE_TIMERS,
      useLoader: false,
      body: isEditMode ? updateActionBody : body,
      successMessage: isEditMode ? "Shop time has been stopped" : "Shop time has been started",
    });

    if (callback) callback();
  };

  return {
    startOfficeTimer,
    stopOfficeTimer,
    toggleOfficeTimer,
  };
};

export default useOfficeTimer;
