const publicLinks = {
  singin: "signin",
  forgotPassword: "forgot-password",
  setPassword: "set-password",
  completeRegistration: "newAccount",
  confirmEmail: "confirm-email",
  forbidden: "forbidden",
  qbConnection: "connection-established",
};

const adminToolLinks = {
  users: "users",
  quickBooks: "quick-books",
  settings: "settings",
};

const technichianToolLinks = {
  schedule: "schedule",
  companyList: "companies",
  companySales: "sales",
  companyMap: "companies/list/map",
  companiesDetails: "companies/:id",
  vehiclesList: "vehicles",
  vehiclesCreate: "vehicles/create",
  vehiclesEdit: "vehicles/edit/:id",
  quotesList: "quotes",
  quotesDetails: "quotes/:id",
  contactsList: "contacts",
  contactsCreate: "contacts/create",
  contactsEdit: "contacts/edit/:id",
  equipmentList: "equipment",
  equipmentCreate: "equipment/create",
  equipmentEdit: "equipment/edit/:id",
  notesList: "companies/notes",
  notesCreate: "companies/notes/create",
  notesEdit: "companies/notes/edit/:id",
  equipmentMakesList: "equipment/makes",
  equipmentMakesCreate: "equipment/makes/create",
  equipmentMakesEdit: "equipment/makes/edit/:id",
  equipmentTypesList: "equipment/types",
  equipmentTypesCreate: "equipment/types/create",
  equipmentTypesEdit: "equipment/types/edit/:id",
  equipmentModelsList: "equipment/models",
  equipmentModelsCreate: "equipment/models/create",
  equipmentModelsEdit: "equipment/models/edit/:id",
  workOrderCreate: "work-orders/create",
  workOrderDetails: "work-orders/:id",
  workOrdersNotesCreate: "work-orders/notes/create",
  workOrdersNotesEdit: "work-orders/notes/edit/:id",
  workOrdersList: "work-orders",
  myProfile: "profile",
  payroll: "payroll",
};

const clientAppLinks = {};

export { publicLinks, adminToolLinks, clientAppLinks, technichianToolLinks };
