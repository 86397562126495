import { IUser } from "core/types/base/IUser";
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FirstDayOfTheWeek } from "core/types/enums/FirstDayOfTheWeek";
import { RootState } from "store";
import { ILocalTimer } from "core/types/entities/IExpense";
import type TAppState from "./type";
import { ITimeZones } from "../../../core/types/entities/IItem";

const initialState: TAppState = {
  currentUser: null,
  accessToken: "",
  refreshToken: "",
  showLoader: false,
  notification: { message: "", type: "success" },
  error: {},
  appInitialized: false,
  timers: [],
  serverTimezone: null,
  timezones: [],
  firstDayOfTheWeek: FirstDayOfTheWeek.Monday,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTimezones: (state: TAppState, action: PayloadAction<ITimeZones[]>) => {
      state.timezones = action.payload;
    },
    setServerTimezone: (state: TAppState, action: PayloadAction<ITimeZones>) => {
      state.serverTimezone = action.payload;
    },
    setAccessToken: (state: TAppState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state: TAppState, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setLoader: (state: TAppState, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setError: (state: TAppState, action: PayloadAction<Object | null>) => {
      state.error = action.payload;
    },
    setNotification: (
      state: TAppState,
      action: PayloadAction<{ type: "error" | "success"; message: string }>,
    ) => {
      state.notification = action.payload;
    },
    setCurrentUser: (state: TAppState, action: PayloadAction<IUser | null>) => {
      state.currentUser = action.payload;
    },
    setAppInitialized: (state: TAppState, action: PayloadAction<boolean>) => {
      state.appInitialized = action.payload;
    },
    setTimer: (state: TAppState, action: PayloadAction<ILocalTimer[]>) => {
      state.timers = action.payload;
    },
    setFirstDayOfTheWeek: (state: TAppState, action: PayloadAction<FirstDayOfTheWeek>) => {
      state.firstDayOfTheWeek = action.payload;
    },
  },
});

// Selectors (optional)
export const selectAccessToken = (state: RootState) => state.app.accessToken;
export const selectAppInitialized = (state: RootState) => state.app.appInitialized;
export const selectCurrentUser = (state: RootState) => state.app.currentUser;
export const selectTimers = (state: RootState) => state.app.timers;
export const selectServerTimezone = (state: RootState) => state.app.serverTimezone;
export const selectTimezones = (state: RootState) => state.app.timezones;

// Actions
export const {
  setAccessToken,
  setLoader,
  setError,
  setNotification,
  setCurrentUser,
  setAppInitialized,
  setRefreshToken,
  setTimer,
  setServerTimezone,
  setTimezones,
  setFirstDayOfTheWeek,
} = appSlice.actions;

export default appSlice.reducer;
